import {
  Button,
  Col,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";
import { GroupItem } from "../models/Group";
import CustomModal from "./CustomModal";
import { useEffect, useState } from "react";
import AddEditGroup from "./AddEditGroup";
import { getGroups } from "../services/Groups";
import Spinner from "./Spinner";
import HideUnhideGroup from "./HideUnhideGroup";
import { NotificationPaginator } from "./NotificationPaginator";
import { FaSearch, FaTimes } from "react-icons/fa";

const GroupEntries = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showVisibilityModal, setShowVisibilityModal] =
    useState<boolean>(false);
  const [groups, setGroups] = useState<GroupItem[]>([]);
  const [formData, setFormData] = useState<any>(null);
  const [pageData, setPageData] = useState<any>(null); // For pagination data
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [groupFilter, setGroupFilter] = useState(""); // Search filter state
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 9; // Number of items per page

  const loadGroups = () => {
    setLoading(true);
    const getGroups$ = getGroups({
      userGroups: false,
      page: pageNumber,
      pageSize: itemsPerPage,
      search: groupFilter,
    });
    const sub = getGroups$.subscribe({
      next: (res: any) => {
        res.results.map((item: any) => {
          if (item.groupprop == null) {
            item.groupprop = {
              is_visible: true,
              group: item.id,
              groupFilter,
              itemsPerPage,
            };
          }
        });
        setPageData(res);
        setGroups(res.results);
        setLoading(false);
      },
    });
    return () => sub.unsubscribe();
  };

  useEffect(() => {
    loadGroups();
  }, [pageNumber, groupFilter]);

  // Handle page change for pagination
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  // Handle search query when the user presses "Enter"
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setGroupFilter(searchQuery);
      setPageNumber(1);
    }
  };

  // Handle manual search via the search button
  const handleSearch = () => {
    setGroupFilter(searchQuery);
    setPageNumber(1);
  };

  return (
    <>
      <CustomModal
        show={showAddModal}
        scrollable={false}
        modalBody={
          <AddEditGroup
            formData={formData}
            onClose={() => {
              setShowAddModal(false);
              setFormData(null);
              loadGroups();
            }}
          />
        }
      />
      <CustomModal
        show={showVisibilityModal}
        scrollable={false}
        modalBody={
          <HideUnhideGroup
            formData={formData}
            onClose={() => {
              setShowVisibilityModal(false);
              setFormData(null);
              loadGroups();
            }}
          />
        }
      />
      <Row className="mb-2 justify-content-md-end g-2">
        {/* Search Input - Expands to Full Width on Small Screens */}
        <Col xs={12} md="auto" className="mb-2 mb-md-0">
          <InputGroup>
            <FormControl
              placeholder="Search by Name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyPress} // Trigger search on Enter
            />
            {searchQuery && (
              <span
                onClick={() => {
                  setSearchQuery("");
                  setGroupFilter("");
                  setPageNumber(1);
                }}
                style={{
                  position: "absolute",
                  right: "55px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                <FaTimes />
              </span>
            )}
            <Button variant="secondary" onClick={handleSearch}>
              <FaSearch />
            </Button>
          </InputGroup>
        </Col>

        {/* Add Group Button - Expands to Full Width on Small Screens */}
        <Col xs={12} md="auto">
          <Button
            variant="primary"
            onClick={() => setShowAddModal(true)}
            className="w-100 w-md-auto"
          >
            + Add Group
          </Button>
        </Col>
      </Row>
      {/* No results found */}
      {groups.length === 0 && (
        <div className="mt-3 d-flex justify-content-center bg-white">
          <div className="no-results-found p-4 text-center">
            <h5>No Groups Found</h5>
            <p>Try adjusting your search or adding new groups.</p>
          </div>
        </div>
      )}

      <Row className="position-relative g-2">
        {/* Added g-2 for consistent gutter */}
        <ListGroup>
          {groups.map((group) => (
            <ListGroup.Item
              className="d-flex justify-content-between align-items-start mb-1 px-3"
              key={group.id}
            >
              <div className="text-start me-auto p-0 list-item-wrapper">
                <div
                  className={`${
                    group.groupprop.is_visible
                      ? "fw-bold small-device-text"
                      : "text-muted small-device-text"
                  }`}
                  style={{ whiteSpace: "normal" }} // Allow text to wrap
                >
                  {group.name}
                </div>
              </div>
              <div className="list-item-wrapper d-flex align-items-center">
                <button
                  type="button"
                  className={`btn list-action-button align-items-center btn-light ${
                    group.groupprop.is_visible ? "" : "disabled"
                  }`}
                  onClick={() => {
                    setFormData(group);
                    setShowAddModal(true);
                  }}
                >
                  <i
                    className="bi bi-pencil-square fs-5"
                    id={`edit-group-entry-${group.id}`}
                  ></i>
                </button>

                <button
                  type="button"
                  className="btn list-action-button btn-light ms-2"
                  onClick={() => {
                    setFormData(group);
                    setShowVisibilityModal(true);
                  }}
                >
                  <i
                    className={
                      group.groupprop.is_visible
                        ? "bi bi-eye fs-5"
                        : "bi bi-eye-slash fs-5"
                    }
                    id={`hide-group-entry-${group.id}`}
                  ></i>
                </button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
        {/* Display spinner overlay while loading */}
        {loading && (
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.7)", zIndex: 1 }}
          >
            <Spinner />
          </div>
        )}
      </Row>
      {/* Paginator */}
      {pageData && (
        <NotificationPaginator
          currentPage={pageNumber}
          totalItems={pageData.count}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default GroupEntries;
