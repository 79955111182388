import { useContext, useEffect, useState } from "react";
import { Dropdown, NavDropdown, NavLink, Nav } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap";
import { SignInContext } from "../context/SignInContext";
import { FaBell } from "react-icons/fa";
import Notifications from "./Notifications";
import { useNotificationItemsContext } from "../context/NotificationContext";

const UserMenu: React.FC = () => {
  const auth = useAuth();
  const { isAdminUser, userGroupsCount } = useContext(SignInContext);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const { unreadCount, fetchUnreadCount } = useNotificationItemsContext();

  useEffect(() => {
    fetchUnreadCount();
  }, [fetchUnreadCount, unreadCount]);

  if (!auth.isAuthenticated) {
    return (
      <Nav.Link
        className={"text-black nav-1-menu mt-auto"}
        onClick={() => {
          auth.signinRedirect();
        }}
      >
        Sign In
      </Nav.Link>
    );
  }

  const signOutRedirect = () =>
    auth
      .revokeTokens(["access_token", "refresh_token"])
      .then(() => {
        console.log("tokens revoked");

        return auth.removeUser();
      })
      .catch((_err) => Promise.reject("Logout Error: err"));

  return (
    <>
      <Notifications open={showNotifications} setOpen={setShowNotifications} />
      <Nav className="d-flex align-items-center">
        <LinkContainer to="/dashboard">
          <Nav.Link className="me-2">Dashboard</Nav.Link>
        </LinkContainer>

        <LinkContainer to="">
          <Nav.Link
            data-testid="desktop-view-notification"
            onClick={() => setShowNotifications(true)}
            className="position-relative p-2"
          >
            <span className="visually-hidden">View notifications</span>
            <FaBell size={19} className="me-2" />

            {unreadCount > 0 && (
              <span
                className="position-absolute top-5 translate-middle-x badge rounded-pill bg-danger"
                style={{
                  top: "0", // Move badge slightly down from top
                  right: unreadCount > 9 ? "-10px" : "-5px", // Adjust position for 1 or 2 digits
                  fontSize: "0.75rem", // Adjust font size
                  padding: unreadCount > 9 ? "0.25em 0.35em" : "0.25em 0.5em", // Adjust padding for consistent shape
                  zIndex: 1, // Ensures the badge stays above the icon
                }}
              >
                {unreadCount}
                <span className="visually-hidden">unread notifications</span>
              </span>
            )}
          </Nav.Link>
        </LinkContainer>

        <Dropdown className="d-flex align-items-center">
          <Dropdown.Toggle id="user-dropdown" as={NavLink}>
            {auth.user?.profile.given_name ||
              auth.user?.profile.name ||
              auth.user?.profile.preferred_username ||
              auth.user?.profile.email}
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {userGroupsCount != null && userGroupsCount > 0 && (
              <>
                <LinkContainer to="/manage-cameras">
                  <NavDropdown.Item>Manage Cameras</NavDropdown.Item>
                </LinkContainer>

                {isAdminUser && (
                  <LinkContainer to="/manage-groups">
                    <NavDropdown.Item>Manage Groups</NavDropdown.Item>
                  </LinkContainer>
                )}
                <NavDropdown.Divider />
              </>
            )}
            <Dropdown.Item onClick={() => signOutRedirect()}>
              Sign out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </>
  );
};

export default UserMenu;
