import { useCallback, useEffect, useState } from "react";
import { getMedias } from "../services/Media";
import { MediaItem } from "../models/Media";
import { CameraItem } from "../models/Camera";
import { useSelectedMediaContext } from "../context/MediaContext";
import { Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import IdentifiedSpecies from "./IdentifiedSpecies";
import { useSelectedIdentifiedObjectContext } from "../context/IdentifiedObjectContext";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";
import { NotificationPaginator } from "./NotificationPaginator";

initializeIcons();

interface MediaProps {
  selectedCameras: CameraItem[];
  groupId?: number;
}

const PAGE_SIZE = 8;

const Media: React.FC<MediaProps> = ({ selectedCameras, groupId }) => {
  const { setSelectedMedia } = useSelectedMediaContext();
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [selectedDate, _setSelectedDate] = useState<Date | null>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [_hasMore, setHasMore] = useState(true);
  const [totalItems, setTotalItems] = useState(0); // New state to track the total number of media
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [specificDate, _setSpecificDate] = useState<string>("");
  const { selectedIdentifiedObjects } = useSelectedIdentifiedObjectContext();
  const [selectedItemId, setSelectedItemId] = useState<number>(0);

  // Helper function to format date to "YYYY-MM-DD"
  const formatDate = (date: string | Date | null) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };

  const loadMedia = useCallback(() => {
    if (isLoading) return;

    setIsLoading(true);

    const selectedCameraIds = selectedCameras.map((camera) => camera.id);
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    console.log("Fetching media with params:", {
      selectedCameraIds,
      formattedStartDate,
      formattedEndDate,
      groupId,
      selectedIdentifiedObjects,
      page,
      PAGE_SIZE,
    });

    getMedias(
      selectedCameraIds,
      formattedStartDate,
      formattedEndDate,
      groupId,
      selectedIdentifiedObjects,
      page,
      PAGE_SIZE
    ).subscribe({
      next: (res: any) => {
        console.log("API response:", res);
        const items = res.results.map((item: MediaItem) => ({
          ...item,
        }));
        console.log("all the items", items);
        setMediaItems(items); // Replace the items
        setTotalItems(res.count); // Set total count from API response
        setHasMore(items.length === PAGE_SIZE); // Check if there's more data to load
      },
      error: (error: string) => {
        console.error("Error fetching media:", error);
      },
      complete: () => setIsLoading(false),
    });
  }, [
    selectedCameras,
    startDate,
    endDate,
    specificDate,
    groupId,
    selectedIdentifiedObjects,
    page,
    isLoading,
  ]);

  useEffect(() => {
    // Reset media items and page when date or cameras change
    setMediaItems([]);
    setPage(1);
    setHasMore(true);
    loadMedia();
  }, [
    selectedDate,
    selectedCameras,
    startDate,
    endDate,
    specificDate,
    groupId,
    selectedIdentifiedObjects,
  ]);

  useEffect(() => {
    loadMedia();
  }, [page]);

  const handleMediaSelect = (media: MediaItem) => {
    setSelectedMedia(media);
    setSelectedItemId(media.id);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="mt-2">
      <IdentifiedSpecies mode={"multi"} />
      <span className="d-block fw-bold mb-1">
        <small>Date Range:</small>
      </span>
      <Card
        border="light"
        className="bg-white shadow-sm rounded border-1 p-1 koala-card mt-1"
      >
        <Card.Body className="py-0">
          <Form>
            <div className="row">
              <Form.Group className="col-md-6 mb-2" controlId="start-date">
                <Form.Label>
                  <small>Start Date:</small>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="form-control-sm"
                  style={{ marginTop: "-5px" }}
                />
              </Form.Group>

              <Form.Group className="col-md-6" controlId="end-date">
                <Form.Label>
                  <small>End Date:</small>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="form-control-sm"
                  style={{ marginTop: "-5px" }}
                />
              </Form.Group>
            </div>
          </Form>
        </Card.Body>
      </Card>

      {/* Conditional Spinner */}
      {isLoading ? (
        <div className="text-center my-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : mediaItems.length > 0 ? (
        <Card className="bg-white shadow-sm rounded border-1 koala-card mt-2">
          <Card.Body className="p-0">
            <ListGroup className="rounded-top">
              {mediaItems.map((media, index) => (
                <ListGroup.Item
                  key={media.id}
                  action
                  onClick={() => handleMediaSelect(media)}
                  style={{
                    backgroundColor:
                      media.id === selectedItemId ? "#f0f0f0" : "white",
                    borderBottom: "1px solid #dddd",
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderRadius: index === 0 ? "8px 8px 0 0" : "0", // Top border-radius only for the first item
                  }}
                >
                  <a>
                    <small>{media.title}</small>
                  </a>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Row className="px-2 d-flex justify-content-between align-items-center">
              <Col>
                <NotificationPaginator
                  currentPage={page}
                  totalItems={totalItems}
                  itemsPerPage={PAGE_SIZE}
                  onPageChange={handlePageChange}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <div>No media available for the selected date or criteria.</div>
      )}
    </div>
  );
};

export default Media;
